.nexticon {
  visibility: visible;
  color: black !important;
}
.carouseldiv {
  padding-top: 50px;
  padding-bottom: 50px;
}
.avatardiv {
  width: 0% !important;
  margin: auto !important;
  text-align: center !important;
  justify-content: center !important;
  display: flex;
  margin-top: 10px !important;
}
.testcaption {
  margin-top: 200px;
}
.testimonialshead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  /* margin-bottom: 50px !important; */
  margin-top: 20px;
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;

  position: relative;
}
.testimonialshead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 590px; */
  /* top: 0; */
  top: 40px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}
.carouselimg {
  text-align: center !important;
}
.carousel-data {
  width: 60%;
  font-size: 14px !important;
  text-align: center !important;
  justify-content: center !important;
  margin: auto !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  min-width: 300px;
  /* margin-top:50px !important; */
  color: #5c5c5c;
}
@media screen and (max-width: 1024px) {
  .carouseldiv {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  /* .carousel-control-next-icon, .carousel-control-prev-icon {
        width: 1rem !important;
        height: 1rem !important;
    } */
  .testcaption {
    font-size: 10px !important;
  }
  /* .avatardiv{width: 10% !important;} */
}

@media screen and (max-width: 900px) {
  .carouselimg img {
    width: 20% !important;
  }

  .carousel-data {
    font-size: 14px !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 2rem !important;
    height: 2em !important;
  }
}

.carousel-item .carousel-data {
  display: grid !important;
  justify-items: center !important;
  padding-top: 80px;
  min-height: 200px;
}

.carousel-indicators {
  display: none !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 3rem !important;
  height: 3em !important;
  background-repeat: no-repeat;
  background-position: 50% !important;
  background-size: 100% 100%;
  margin-top: 60px !important;
}
