.headerdiv {
  background-image: url("headerimg.png");
  background-repeat: no-repeat !important;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-size: cover;
  object-fit: contain;
  height: 400px !important;
  position: relative;
}

.firstnav-section {
  background: rgba(0, 0, 0, 0.2);
  padding: 1px 28px !important;
  color: white;
}

.phone {
  display: flex;
  justify-content: end;
  margin-top: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}

.phone p {
  padding-top: 4px;
}

.phone a {
  color: white;
  padding-top: 4px;
}

.phone a:hover {
  color:white;
}

.socialmedia {
  padding-left: 15px;
  font-size: 26px;
}

.brand-name {
  display: none;
}

.social-media a {
  width: 25px;
  text-decoration: none;
}

@media screen and (min-width: 320px) {
  .head-centersection {
    margin-top: 140px !important;
  }
  .head-subtitle {
    margin-bottom: 30px;
  }

}

@media screen and (max-width:480px) {
  .firstnav-section {
    background: rgba(0, 0, 0, 0.2);
    padding: 0px 25px !important;
    color: white;
  }
}

@media screen and (max-width: 980px) {
  .head-centersection {
    margin-top: 140px !important;
  }
 
}

.head-centersection {
  margin: auto !important;
  text-align: center !important;
  margin-top: 68px !important;
}

.head-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
  /* identical to box height */

  color: #ffffff;

  text-shadow: 0px 4px 4px #323131;
}

.head-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 44px;
  /* identical to box height */
  margin-top: -15px;
  color: #ffffff;

  text-shadow: 0px 4px 4px #363535;
}

.leftlogodiv {
  display: flex;
  align-items: center;
  position: absolute;
  left: 30px;
}

.leftlogodiv img {
  width: 30% !important;
}

.leftlogo-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;

  color: #ffffff;
  padding: 20px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.67);
}

/* mainnavigation bar */

.navbar {
  height: 100px;
  width: 80%;
  display: grid !important;
  margin: auto;
  align-items: center !important;
  justify-content: center !important;
  position: relative;
  background-color: white !important;
  top: 40px;
  border-radius: 25px;
  z-index: 1;
  transition: 0.1s;
  /* transition-duration: .1s; */
  box-shadow: 0px 4px 11px 11px rgba(0, 0, 0, 0.07)
}

.navbar .nav-item {
  font-size: 20px;
}

.navbar-nav > li {
  /*float: none;*/
  display: table-cell;
  text-align: center;
  padding: 10px;
}

#home .navbar-nav > .active > a:after {
  content: "";
  position: absolute;
  width: 70px;
  height: 100%;
  right: 32%;
  bottom: 0;
  top: 34px;
  text-align: center;
  background: url(../../assets/images/underline.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

*,
::after,
::before {
  box-sizing: border-box;
}

#home .navbar-nav > .active > a {
  color: #000;
  display: block;
  position: relative;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.headerlogo {
  width: 10%;
}
.nav-item {
  font-family: "Poppins";
  font-style: normal;
}
/* 
@media screen and (max-width: 500px) {
 .headerlogo{width: 20%;}
}

@media (max-width: 501px) and (min-width:1300px){
  .headerlogo{width: 30%;}
  .head-title{font-size: 16px !important;line-height: 35px ;}
.head-subtitle{font-size: 14px !important;line-height: 35px;}

} */

@media (min-width: 700px) {
  .coursehead {
    margin-top: 190px;
  }
}

@media (max-width: 991.98px) {
  /* .headerdiv{overflow: hidden;} */
  .navbar-nav {
    display: grid !important;
    justify-content: center;
    align-items: center !important;
    width: 80% !important;
    position: relative;
  }

  #home .navbar-nav > .active > a:after {
right: 10% !important;

  }

  .coursehead {
    margin-top: 150px;
  }

  .headerlogo {
    width: 20%;
    /* padding-top: 20px; */
  }

  ul.navbar-nav {
    margin: auto !important;
  }

  .bg-light {
    height: max-content;
  }

  .social-media a {
    padding-right: 5px;
    font-size: 18px;
  }

  .head-title {
    font-size: 20px;
    line-height: 35px;
  }
  .head-subtitle {
    font-size: 14px;
    line-height: 35px;
  }
  /* .head-centersection .headerlogo{width: 30%;margin-top: 0px;} */
  .headerdiv {
    /* background-size: cover !important; */

    /* height: 400px !important; */

    /*  */
    /* height: 56vh !important;
    display: table !important;
    margin: 0;
    max-width: none;
    border-radius: 0 0 39px 39px !important;

    background-size:  1000px !important; */

    background-position: center top;
  }

  .navbar {
    /* height: 100%; */
    width: 80%;

    position: relative;
    background-color: white !important;
    top: -20px;
    border-radius: 25px;
    /* box-shadow: 0px 6px 1px 1px rgba(182, 182, 182, 0.67); */
    box-shadow: 0px 16px 26px 0px #00000033;

    display: flex !important;
    flex-wrap: wrap !important;
    align-items: flex-start !important;
    justify-content: center !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    align-content: flex-start !important;
  }

  .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .navbar-expand .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}


@media (min-width:1440px) {
  .navbar{
    transform: translate(0px , -10px) !important;
  }
}

@media (min-width: 992px) {
  .navbar.nav-sticky {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    border-radius: 0 !important;
    margin: auto;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    transition: 1s;
  }
  /* 
  .navbar.nav-sticky .nav-item {
    padding: 30px;
  } */

  .head-title {
    font-size: 29px !important;
    line-height: 60px !important;
  }
  .head-subtitle {
    font-size: 20px !important;
    line-height: 10px !important;
  }

  .navbar-nav > li {
    /* float: none; */
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 16px !important;
  }

  #home .navbar-nav > .active > a:after {
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    right: 32%;
    bottom: 0;
    left: 10%;
    top: 36px;
    text-align: center;
    background: url(../../assets/images/underline.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .navbar.nav-sticky .brand-name {
    display: block !important;
    /* padding-right: 100px; */
  }
  
  .navbar.nav-sticky {
    display: flex !important;
  }

  .navbar.nav-sticky .nav-item {
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 0.55);
    font-size: 20px !important;
  }

  .list-inline-item {
    display: inline-block !important;
  }

  .white-shadow {
    color: #ffffff;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 67%);
    font-size: 28px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
  }

  .brand-name img {
    width: 40%;
    margin: auto !important;
    /* padding-top: 20px !important; */
  }
  .brand-name p {
    padding-left: 15px !important;
    color: #000;
    font-weight: bold;
  }
  .navbar {
    width: 90%;
    margin-top: -20px;
  }
}


