.downloadhead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  /* margin-bottom: 50px !important; */
  position: relative;
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;
}
.downloadhead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 580px; */
  /* top: 0; */
  top: 75px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}

/* @media  screen and (max-width:1200px){
    .downloadhead::after{
top: 130px;
    } */

/* } */
.downloadsubhead {
  color: #5c5c5c;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 57px;
  text-align: center;
  margin-bottom: 50px !important;
  position: relative;
  display: table !important;
  margin-top: 40px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.disclaimer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 37px;
  /* identical to box height */

  color: #2f2e41;
}
.subdisclaimer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  padding-top: 20px;

  color: #2f2e41;
}
.logodiv-download {
  padding-top: 50px;
}

.downloadlogo1 img {
  width: 40%;
  display: grid !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  margin: auto !important;
}
.downloadlogo2 img {
  width: 40%;
  display: grid !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  margin: auto !important;
  /* float:right !important; */
}
.downloadphone img {
  width: 40%;
  display: grid !important;
  float: right;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  margin: auto !important;
  /* float: right; */
}
.downloaddiv {
  padding-bottom: 0px !important;
}
@media screen and (max-width: 900px) {
  .logodiv-download {
    padding-top: 0px !important;
  }

  .disclaimer {
    font-size: 12px !important;
  }
  .subdisclaimer {
    font-size: 10px !important;
  }
  .downloadsubhead {
    font-size: 14px !important;
  }
  .downloadhead {
    font-size: 25px !important; /* top: 100px !important;left: 100px !important; */
  }
}
@media screen and (max-width: 700px) {
  .downloadphone img {
    width: 30% !important;
    float: none !important;
  }
  .disclaimer {
    padding-top: 20px;
  }
  .downloadlogo img {
    width: 40% !important;
    padding-top: 20px;
  }
}
