.universitieshead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  position: relative;
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 100px;
}
#universities {
  background-color: #dddcdc;

  /* padding-top: 100px;
    padding-bottom: 30px; */
}
.universityinner {
  background-color: white !important;
}
.logodiv {
  padding-top: 50px;
  padding-bottom: 70px;
}
.universitieshead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 700px; */
  /* top: 0; */
  top: 140px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}
.universitydiv {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
  padding-top: 100px;
  padding-bottom: 100px;
}

.logosection {
  /* display: flex !important; */
  /* width: 80% !important; */
  margin: auto !important;
  text-align: center !important;
  align-items: baseline !important;
}
.universitybtn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  font-size: 14px;
  line-height: 33px;
  /* identical to box height */
  /* width: 100% !important; */
  color: #000000 !important;
  padding: 10px;
}

@media screen and (max-width:800px) {

  .universitieshead{
    padding-top: 50px;
  }

  .universitieshead::after{
    top: 90px;
  }
 

}

@media screen and (max-width: 1024px) {
  .universitybtn,
  .color {
    font-size: 12px !important;
  }
  .logoviewbtn {
  }
}
.logoviewbtn {
  padding-top: 0px !important;
  /* width: 100% !important; */
  text-align: center !important;
  padding-bottom: 30px;
}
.logosection img {
  width: 40%;
  padding-bottom: 50px;
}

.s {
  display: none;
}
