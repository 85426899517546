.footerimg img {
  width: 100% !important;
}

.footerdiv {
  margin-top: 0px;
  /* margin-bottom: 50px; */
}

.copyrightdiv {
  background: #2f2f2f;
  width: 100%;
  height: 60px;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center !important;
  line-height:0px;
  display: grid;
  justify-content: center;
  align-items: center;
  margin: auto;
  
}

.copyrightcontent a{
text-decoration: none;
color: white;
}

.copyrightcontent a:hover{
  color:  #F8D91E;
  }

.copyrightdiv .copyrightcontent {
 margin-top: 20px;
 
  /* padding-top: 10px !important; */
}

.footermain {
  background: #393939;
  color: #ffffff;
}

.footerlist {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px ;
  line-height: 15px;
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-content: center !important;
  color: #ffffff;
  padding-top: 60px;
}
.footerlist a{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px ;
  line-height: 15px;
  width: 100%;
  
  color: #ffffff;
  
}
.footerlist a:hover{
  color: #ffa800;
}
.footerlist ul {
  margin-bottom: 50px !important;
}
.foot_maincontent {
  width: 100%;
  margin: auto !important;
}

.foot_maincontent .footermap {
  border-radius: 30px;
  width: 80% !important;
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  margin: auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.foot_maincontent .contentgrid {
  width: 100% !important;
  border-right: 5px solid #ffa800;
  display: grid !important;
  justify-content: center !important;
  /* margin: auto !important; */
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mapgrid {
  margin: auto !important;
}

.footerlogo {
  width: 30% !important;
  padding-bottom: 0px !important;
  /* margin-bottom: 20px !important; */
}
.logodiv {
  /* display: flex !important; */
  margin: auto !important;
  text-align: center;
  justify-content: center !important;
  padding-bottom: 5px;
}

.footerlogo-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  text-shadow: 0px 1px 1px #000000;
}

.footerlogo-head-name{
  margin-bottom: 0;
}
.footerlogo-head-company{
  font-size: 12px;
}


.address {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.89);
}
.icon {
  /* width: 20%; */
  font-size: 30px;
  display: flex !important;
  justify-content: center;
  /* padding-right: 10px; */
  margin-right: 20px;
}

.add-line {
  display: flex !important;
  justify-content: baseline !important;
  padding-top: 20px;
}
.add-line a{
  color: #ffffff;

}
.add-line a:hover{
  color: #ffa800;
  text-decoration: underline;
}


.css-11lq3yg-MuiGrid-root {
  padding-bottom: 80px;
}
@media only screen and (max-width: 1000px) {
  .foot_maincontent .contentgrid {
    border-right: none !important;
  }
}
@media screen and (max-width: 900px) {
  .copyrightdiv {
    font-size: 10px !important;
    height: 100px;
  }
  .footerlogo-head {
    padding-inline: 40px;
  }
  .address {
   display: grid !important;
   justify-content: center !important;
   margin: auto !important;font-size: 12px !important;
   padding-inline: 30px !important;
  }
  .add-line a{font-size: 12px !important;}
  .footerlist{font-size: 10px !important;}
  .icon{font-size: 25px !important;margin-right: 10px;}
  .add-line {
    /* justify-content: center !important; */
  }
}
