.cardcertificate{
    border: 10px black solid;
    
    max-width: 220px;
    border-radius: 0px !important;
    margin: auto !important;
    max-height: 350px !important;
}
.imagecertificate{

width: 184px !important;
}
.hangerdiv img{
    margin: auto !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
    display: flex !important;
    margin-bottom: -10px !important;
}