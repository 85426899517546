.gallaryinner {
  background-color: white !important;

  /* height: max-content;
position: absolute;
top: 50px; */
}
.gallaryouter {
  background-color: #dddcdc;

  /* margin-bottom: 150px !important; 
    margin-top: 150px !important; */
  padding-top: 100px !important;
  padding-bottom: 100px !important;
  /* position: relative; */
}

.gallaryhead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  /* text-decoration-line: underline;
    text-decoration: underline #F8D91E 5px; */
  /* margin-bottom: 50px !important; */
  position: relative;
  display: table !important;
  padding-top: 100px;

  margin-left: auto !important;
  margin-right: auto !important;
}
.gallaryhead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 600px !important; */
  /* top: 0; */
  top: 140px !important;
  float: right !important;
  text-align: end !important;
  background-size: contain;
  background-repeat: no-repeat;
}

.gallarygrid{
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
  /* padding: 79px 0px 10px!important */
}


.gallaryviewbtn {
  padding-top: 30px !important;
  width: 100% !important;
  text-align: center !important;
  padding-bottom: 30px !important;
}
.gallarybtn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  line-height: 33px;
  /* identical to box height */
  /* width: 100% !important; */
  color: #000000 !important;
  padding: 10px !important;
}

/*  */

@media screen and (max-width: 1024px) {
  .gallarybtn,
  .color {
    font-size: 16px !important;
  }
  .gallarysubgrid img{
width: 100% !important;
object-fit: cover;
}
}
@media(max-width:1440px) and (min-width: 1024px) {
  .gallarysubgrid img{
    width: 500px !important;
    /* height: 300px !important; */
    object-fit: cover;
    }
}

@media screen and (max-width:800px) {

  .gallaryhead{
    padding-top: 50px;
  }

  .gallaryhead::after{
    top: 90px !important;
  }

}

.hiddenimage{
  margin-top: 30px;
}