.gallery-view {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin-top: 130px;
  }
  
  .gallery-view::after {
      content: "";
      position: absolute;
      width: 130px;
      height: 100%;
      /* right: 0 !important; */
      background: url(../../assets/images/underline.svg);
      background-repeat: no-repeat;
      bottom: 0;
      left: 600px !important;
      /* top: 0; */
      top: 575px !important;
      float: right !important;
      text-align: end !important;
      background-size: contain;
      background-repeat: no-repeat;
    }
  
  .gallery-video{
    margin-top: 50px;
  }
  
    @media screen and (max-width:980px) {
  
      .gallery-view{
        margin-top: 590px;
      }
      
      .gallery-view::after{
        right: 45px;
        top: 1038px !important;
        left: 130px !important ;
        background-size: contain;
  
      }
    }