.visahead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 50px !important;
  position: relative;
  display: table !important;
  padding-top: 0px !important;
  padding-bottom: 30px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.visahead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  float: right !important;
  text-align: end !important;
  /* left: 600px; */
  /* top: 0; */
  top: 40px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}
.visadiv {
  margin-top: 70px;
  margin-bottom: -80px;
  /* width: 100% !important; */
}
@media screen and (max-width: 1024px) {
  .visadiv {
    margin-top: 70px !important;
    margin-bottom: -80px !important;
  }
}
.visagrid .visabanner {
  margin-top: 50px;
}
.visabanner {
  position: relative;
}

.visabanner img {
  border-radius: 30px !important;
  width: 100% !important;
  height: 500px !important;
}

.visabanner-head {
  position: absolute;

  font-family: "Electrolize" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 121px;
  /* identical to box height */

  color: #ffffff;

  text-shadow: 0px 4px 4px #000000;
}

@media screen and (max-width: 900px) {
  .visabanner img {
    height: 300px !important;
  }
  .visabanner-head {
    font-size: 40px !important;
    text-align: center;
    line-height: 40px !important;
  }
  .visacontent,
  .visalist {
    font-size: 14px !important;
  }
}
@media (min-width: 901px) and (max-width: 1000px) {
  .visabanner img {
    height: 400px !important;
  }

  .visabanner-head {
    font-size: 40px !important;
  }
}
@media (min-width: 1001px) and (max-width: 1200px) {
  .visabanner img {
    height: 400px !important;
  }

  .visabanner-head {
    font-size: 50px !important;
  }
}
@media (min-width: 1201px) and (max-width: 1439px) {
  .visabanner img {
    height: 450px !important;
  }

  .visabanner-head {
    font-size: 60px !important;
  }
}

.visacontent {
  margin-top: 50px !important;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: justify !important;

  color: #5c5c5c;
}

.visalist {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: justify !important;

  color: #5c5c5c;
}

.tik-color {
  color: rgba(255, 168, 0, 1);
  height: 22px;
  width: 22px;
}
