.aboutimg img {
  border-radius: 30px;
  width: 100%;
  object-fit: fill;
}

.aboutdiv {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.aboutgrid .aboutcontent {
  margin-top: 40px;
}
.backimg {
  background-image: url(../../assets/images/convocation.png);
  min-height: 600px;
  /* width: 100%;
height: 100%; */
  background-size: contain;
  background-repeat: no-repeat;
  /* background-position: 100%; */
}
.abouthead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  /* margin-bottom: 50px !important; */
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;
}
.abouthead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 580px; */
  /* top: 0; */
  top: 70px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}
.aboutcontent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: justify !important;
  width: 80%;
  margin: auto;
  /* padding-top: 80px; */
  justify-content: center !important;
  /* min-height: 600px; */
  color: #5c5c5c;
  /* margin-bottom: 50px !important; */
}
.aboutimg {
  position: relative;
}
.aboutimg-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 66px;
  position: absolute;
  top: 50%;
  left: 30%;

  color: #ffffff;
  transform: translate(-50%, -50%);
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.99);
}
@media screen and (max-width: 900px) {
  .aboutcontent {
    font-size: 14px !important;
  }
  .aboutimg-head {
    font-size: 20px;
    line-height: 40px;
    left: 40%;
    /* transform: translate(30%, 30%); */
  }
}

@media screen and (max-width: 1200px) {
  .aboutimg-head {
    font-size: 18px;
    line-height: 40px;
    left: 33%;
    /* transform: translate(30%, 30%); */
  }

  .aboutimg img {
    height: 150px !important;
  }
}
