.contactdiv {
  padding-top: 100px;
  padding-bottom: 100px;
}
/* input::placeholder {
  color: red !important;
  opacity: 1;
}

input:-ms-input-placeholder { 
  color: red !important;
}

input::-ms-input-placeholder {
  color: red !important;
} */

/* .css-Isumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
color:red !important
} */

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(5, 5, 5) !important;
}

.MuiFormLabel-root-MuiInputLabel-root {
  color: green !important;
}

.contacthead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 40px !important;
  position: relative;
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;
}
.contacthead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 580px; */
  /* top: 0; */
  top: 45px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}
.gridcontactbox .watsuphead,
.textfielddiv {
  margin-top: 100px !important ;
}
.gridcontactbox .textfielddiv {
  margin: auto;
  align-items: center !important;
  margin: auto;
  display: grid;
  justify-items: center;
}
.gridcontactbox {
  margin-top: 50px;
}
.contactbox {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  box-shadow: 0px 5px 15px 1px rgb(216, 215, 215) !important;
  /* width: 100% !important; */
  margin: auto !important;
  border-radius: 30px;
}

.watsupimg {
  width: 50%;
  margin: auto !important;
  justify-content: center !important;
  display: grid !important;
  float: right;
}
.watsuphead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  text-align: right !important;
  justify-content: center !important;
  display: block !important;
}

@media screen and (max-width:800px) {
.textfieldmsg {
  width: 81% !important;
}  
}

.textfieldmsg {
  width: 85% !important;
  margin: auto !important;
  background: #f4f4f4;
  display: grid !important;
  border-radius: 32px;
  border: none !important;
}

.textfield {
  background: #f4f4f4;
  width: 80% !important;
  border-radius: 30px;
}

.textfield:hover {
  border: none !important;
}
.textfielddiv {
  border: none !important;
}

.sendbtn {
  background-color: white !important;
  color: black !important;
  text-transform: capitalize !important;
  font-size: 14px;
  width: 150px !important;
  box-shadow: 0px 5px 15px 1px rgb(152, 151, 151) !important;
  border-radius: 30px !important;
}

.color {
  color: rgba(255, 168, 0, 1);
  font-size: 17px;
}

.gridcontactbox .textfielddiv {
}
@media screen and (max-width: 1024px) {
  .contactdiv {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .sendbtn {
    font-size: 12px !important;
  }
  .color {
    color: rgba(255, 168, 0, 1);
    font-size: 17px !important;
  }
  .watsupimg {
    width: 30% !important;
    float: none !important;
  }
  .watsuphead {
    margin-top: 70px !important;
    text-align: center !important;
  }
}
.sendbtndiv {
}
.sendbtndiv .sendbtn {
  margin: auto !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  display: flex !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

fieldset {
  border: none !important;
}
/* span forarrows in btns common class for all pages*/
span {
  font-family: "Poppins" !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  border: none !important;
}

.MuiFormLabel-root {
  padding: 2px 20px !important;
}
