@media screen and (min-width: 760px) {
  .coursehead {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1025px) {
  .coursehead {
    margin-top: -280px;
  }
}

.css-1t6e9jv-MuiCardActions-root {
  padding: 0px !important;
}

.coursehead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  /* text-decoration-line: underline;
    text-decoration: underline #F8D91E 5px; */
  margin-bottom: 50px !important;
  position: relative;
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;
}

.coursehead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 600px !important; */
  /* top: 0; */
  top: 40px !important;
  float: right !important;
  text-align: end !important;
  background-size: contain;
  background-repeat: no-repeat;
}
.coursediv {
  margin-bottom: 100px;
  margin-top: 100px;
}

.coursecontent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: justify !important;

  color: #5c5c5c;
}

.card {
  border-radius: 20px !important;
  box-shadow: 0px 5px 10px 5px rgb(217, 216, 216) !important;
  background: #ffffff;
  margin: auto;
  max-width: 300px;
  min-height: 320px;
  /* max-height: 400px; */
}
/* //viewmoreresponsive */
@media screen and (max-width: 1024px) {
  .modal-footer Button {
    font-size: 12px !important;
  }
  .modal-title {
    font-size: 16px !important;
  }
  .modal-body {
    font-size: 10px !important;
    line-height: 20px !important;
  }
  .viewbtn,
  .color {
    font-size: 12px !important;
  }
  .coursediv {
    margin-bottom: 100px !important ;
    margin-top: 350px !important;
  }
}
/* media */
@media screen and (max-width: 900px) {
  .cardgrid .card {
    margin-top: 10px;
  }
  .cardimg {
    height: 200px !important;
  }
  .coursecontent {
    font-size: 14px !important;
  }
  .card {
    max-width: 260px !important;
  }
  .cardtitle {
    font-size: 12px;
  }
}


@media (min-width:760px) and (max-width:950px) {
  .coursehead{
    margin-top: 190px;
  }
}

@media (min-width: 901px) and (max-width: 1000px) {
  .cardgrid .card {
    margin-top: 10px;
  }
  .cardimg {
    height: 180px !important;
  }
  .card {
    max-width: 260px !important;
  }
  .cardtitle {
    font-size: 10px !important;
  }
}

.cardimg {
  border-radius: 20px;
  height: 240px;
}

.cardgrid {
  /* width: 80% !important; */
  margin: auto !important;
  justify-content: center !important;
  align-content: center !important;
  margin-top: 50px !important;
}

.card-content {
  margin-top: 10px;
  /* max-height: 120px; */
}
.cardtitle {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  /* max-height: 50px; */
  color: #000000;
}
.viewbtn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  font-size: 14px;
  line-height: 33px;
  /* identical to box height */
  /* padding-bottom: 20px; */
  color: #000000 !important;
}
.color {
  color: rgba(255, 168, 0, 1);
  font-size: 14px;
}

/* modal css using in built classes in reatbootsrap */
.modal {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-title {
  font-size: 20px;
  color: #5c5c5c;

  font-family: "Poppins";
}
.modal-body {
  font-size: 16px;
  font-family: "Poppins";
  color: #5c5c5c;
  line-height: 35px;
}
.modal-footer Button {
  font-size: 16px;
  font-family: "Poppins";
  color: #ffffff;
  background-color: #ffc500;
}
a {
  text-decoration: none;
}
