.certificationsdiv {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.certificationsinner {
  background-color: #dddcdc;
  padding-top: 125px;
  padding-bottom: 125px;
  margin-top: 50px;
}
.grid-certificate {
  margin: auto !important;
  justify-content: center !important;
}
.certificationhead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  /* margin-bottom: 50px !important; */
  position: relative;
  display: table !important;
  padding-top: 30px;
  padding-bottom: 30px;

  margin-left: auto !important;
  margin-right: auto !important;
}
.certificationhead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 580px; */
  /* top: 0; */
  top: 75px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}

/* .cert-grid{
    margin-right: 20px;margin-left: 20px;
}  */

.cardcertificate {
  border: 10px black solid;

  max-width: 220px;
  border-radius: 0px !important;
  margin: auto !important;
  max-height: 350px !important;
}
.imagecertificate {
  width: 184px !important;
}
.hangerdiv img {
  margin: auto !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  display: flex !important;
  margin-bottom: -10px !important;
}
