.videohead{

    color: black;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 48px;
    text-align: center;
    position: relative;
    padding-top: 100px;
    padding-bottom: 50px;
    /* margin-bottom: 50px !important; */
    display:table !important;


    margin-left: auto !important;
margin-right: auto !important;

}
.videohead::after{
    content: '';
    position: absolute;
    width: 130px;
    height: 100%;
    right: 0 !important; background-image: url(../../assets/images/underline.svg);
    background-repeat: no-repeat;
    bottom: 0;
    /* left: 580px; */
    /* top: 0; */
    top: 140px;
    /* float: right; */
    /* text-align:end; */
    background-size: contain;
    background-repeat: no-repeat;

}
.videogrid{
    margin: auto;justify-content: center;
}

.css-ssjyr7-MuiGrid-root{
    width: 100% !important;
}

@media screen and (max-width:800px) {

    .videohead{
        padding-top: 50px;
    }

    .videohead::after{
        top: 90px;
    }
  }