.clienthead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  /* text-decoration-line: underline;
    text-decoration: underline #F8D91E 5px;  */
  position: relative;
  display: table !important;

  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 30px;
  padding-bottom: 50px;
  /* margin-top: 50px !important;
    margin-bottom: 50px !important; */
}
.clienthead::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 100%;
  right: 0 !important;
  background-image: url(../../assets/images/underline.svg);
  background-repeat: no-repeat;
  bottom: 0;
  /* left: 620px; */
  /* top: 0; */
  top: 70px;
  /* float: right; */
  /* text-align:end; */
  background-size: contain;
  background-repeat: no-repeat;
}
.clientdiv {
  margin-top: -100px !important;
  margin-bottom: 100px !important;
}
.subhead {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 50px !important;
}

.chipstyle {
  background-color: rgba(255, 255, 255, 1) !important;
  /* box-shadow: 1px 3px 2px 1px rgb(229, 228, 228); */
  box-shadow: 0px 4px 6px 0px #d6d4d4;
  margin-right: 217px !important;
  margin-bottom: 40px !important;
  border-radius: 40px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height */
  min-width: 170px;
  height: 50px;
  color: #000000;
}
@media screen and (max-width: 1300px) {
  .chipstyle {
    min-width: 100px;
  }
}

@media screen and (max-width: 780px) {
  .clientsbtn {
    margin-top: 80px !important;
  }
}


.clientviewbtn {
  padding-top: 30px !important;
  /* width: 100% !important; */
  text-align: center !important;
  padding-bottom: 30px;
}
.clientsbtn {
  font-family: "Poppins" !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  font-size: 14px;
  line-height: 33px;
  /* identical to box height */
  /* width: 100% !important; */
  color: #000000 !important;
  padding: 10px;
}

/* @media (min-width: 0px){
.css-67bn0p-MuiGrid-root{
  width: 0px !important;
  margin-left: -18px !important;
}
} */
@media screen and (max-width: 1024px) {
  .clientsbtn,
  .color {
    font-size: 12px !important;
  }
  .chipstyle {
    font-size: 10px !important;
  }
}


@media screen and (max-width:800px) {

  .client-row{
    transform: translate(50px,50px);
  }
}